<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed, watch } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import CopyIcon from "@magnit/icons/src/assets/icons/copy-24.svg";
import { usePlatformStore } from "~/store/platform";
import useToasts from "~/composables/useToasts";
import useAnalytics from "~/composables/useAnalytics";

const MY_MAGNIT_URL = "https://my.magnit.ru/";

const props = defineProps<{ descriptionText?: string; instruction?: boolean; }>();

const platformStore = usePlatformStore();
const toasts = useToasts();
const { copy, copied } = useClipboard({ legacy: true });
const { send } = useAnalytics();

const isSafari = computed(() => platformStore.platformInfo.browserName === "safari");

const onLinkCopy = () => {
  send(
    props.instruction
      ? "MySettings:Ios:Instruction:Copy:Click"
      : "App:Ios:Instruction:Popup:Copy:Click",
  );
  copy(MY_MAGNIT_URL);
};

const onIntersectionObserver = (
  [{ isIntersecting }]: IntersectionObserverEntry[],
  slideIndex: number,
) => {
  if (isIntersecting) {
    send(
      props.instruction
        ? "MySettings:Ios:Instruction:Slide:View"
        : "App:Ios:Instruction:Popup:Slide:View",
      { tab_index: slideIndex },
    );
  }
};
const onInstructionView = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (props.instruction && isIntersecting) {
    send("MySettings:Ios:Instruction:View");
  }
};

watch(copied, (next) => {
  if (next) {
    toasts.success({ text: "Ссылка скопирована. Перейдите по ней из Safari" });
  }
});
</script>

<template>
  <div v-intersection-observer="onInstructionView" class="ios-pwa-instruction">
    <VText v-if="descriptionText" class="ios-pwa-instruction__subtitle">
      {{ descriptionText }}
    </VText>
    <div v-if="isSafari" class="ios-pwa-instruction__carousel">
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 1)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_1_safari.webp" alt="step one">
      </div>
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 2)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_2_safari.webp" alt="step two">
      </div>
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 3)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_3_safari.webp" alt="step four">
      </div>
    </div>
    <div v-else class="ios-pwa-instruction__carousel">
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 1)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_1.webp" alt="step one">
      </div>
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 2)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_2.webp" alt="step two">
      </div>
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 3)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_3.webp" alt="step three">
      </div>
      <div
        v-intersection-observer="(e: IntersectionObserverEntry[]) => onIntersectionObserver(e, 4)"
        class="ios-pwa-instruction__slide-wrapper"
      >
        <img src="/images/pwa_instruction/step_4.webp" alt="step three">
      </div>
    </div>
    <div v-if="!isSafari" class="ios-pwa-instruction__url-copy" @pointerup.stop="onLinkCopy">
      <VText font="body-large-accent" class="ios-pwa-instruction__url-copy-text">
        Скопировать ссылку <CopyIcon />
      </VText>
    </div>
    <VNotificationsBanner
      class="ios-pwa-instruction__notification-banner"
      @click="send('App:Ios:Instruction:Popup:Sett:Link:Click')"
    />
  </div>
</template>

<style lang="postcss" scoped>
.ios-pwa-instruction {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__subtitle {
    margin: 0 0 var(--pl-unit-x4);
  }

  &__carousel {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    overscroll-behavior-x: contain;
  }

  &__slide-wrapper {
    scroll-snap-align: center;
    width: 240px;
    height: 320px;
    margin-left: var(--pl-unit-x3);
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  img {
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  &__url-copy {
    border-radius: var(--pl-field-radius-m);
    border: 1px dashed var(--pl-push-control-border-checkbox-off-default);
    background: var(--pl-color-tooltip-background-primary);
    cursor: pointer;
    padding: var(--pl-unit-x4);
    margin-top: var(--pl-unit-x4);

    &-text {
      display: inline-flex;
      gap: var(--pl-unit-x2);
    }
  }

  &__notification-banner {
    margin-top: var(--pl-unit-x4);
  }
}
</style>
